<template>
    <SmartForm base_url="/dashboard-message" base_path="/dashboard-message" form_type="edit" :show_list_button="false"
        after_update_url="/dashboard-message/view/" :item_id="1">
    </SmartForm>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Messaggio Dashboard", route: "/dashboard-message/view/1" },
            { title: "Modifica Messaggio Dashboard" }
        ]);
    },
};
</script>
